import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './app/store';
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './lib/bootstrap/css/bootstrap.css';
import './lib/font-awesome/css/all.min.css';
import './css/style.css';
import './css/bss.css';
import './App.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(

  <Provider store={store}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>,
      <ToastContainer />
    </BrowserRouter>
  </Provider>

);


serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
