// src/serviceWorkerRegistration.js
// import { register } from 'service-worker-registration'; or the Service Worker file of your choice

// This is where we'll handle updates



export function register() {

  if (process.env.NODE_ENV === 'production') {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js?swv=${process.env.REACT_APP_SW_VERSION}`;

    navigator.serviceWorker.register(swUrl).then((registration) => {
      console.log(`Service Worker v${process.env.REACT_APP_SW_VERSION} has been updated!`);
      console.log('Service Worker registered: ', registration);

      // Handle updates for the service worker
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            // New content is available and is ready to be used
            if (navigator.serviceWorker.controller) {
              // There is a new version of the app available
              console.log('New content is available and will be used when all tabs are closed.');
              window.location.reload();
            } else {
              // The app has been cached for offline use
              console.log('Content is cached for offline use.');
            }
          }
        };
      };
    }).catch((error) => {
      console.error('Error during service worker registration: ', error);
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
